import React, { Suspense, lazy } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LoadingScreen from './components/LoadingScreen';
import Home from './pages/home/Home';
import ErrorPage from './error-page';
import MainLayout from './layout/MainLayout';
import ProductsLayout from './layout/ProductsLayout';
import AboutLayout from './layout/AboutLayout';
import { contactAction } from './pages/contact/Contact';

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// lazy load pages
// const Home = Loadable(lazy(() => import("./pages/home/Home")));
const About = Loadable(lazy(() => import('./pages/about/About')));
const Products = Loadable(lazy(() => import('./pages/products/Products')));
const Clients = Loadable(lazy(() => import('./pages/clients/Clients')));
const OurService = Loadable(
  lazy(() => import('./pages/our-service/OurService'))
);
const Associates = Loadable(
  lazy(() => import('./pages/associates/Associates'))
);
const Contact = Loadable(lazy(() => import('./pages/contact/Contact')));

// products pages

const AttendanceAndPayroll = Loadable(
  lazy(
    () =>
      import(
        './pages/products/pages/attendance-and-payroll/AttendanceAndPayroll'
      )
  )
);
const HospitalManagementSystem = Loadable(
  lazy(() => import('./pages/products/pages/hms/HospitalManagementSystem'))
);
const IntegratedSolidWasteManagementSystem = Loadable(
  lazy(
    () =>
      import('./pages/products/pages/iswm/IntegratedSolidWasteManagementSystem')
  )
);
const PointOfSale = Loadable(
  lazy(() => import('./pages/products/pages/pos/PointOfSale'))
);
const ReservationAndBooking = Loadable(
  lazy(
    () =>
      import(
        './pages/products/pages/reservation-and-booking/ReservationAndBooking'
      )
  )
);
const StudentInformationSystem = Loadable(
  lazy(() => import('./pages/products/pages/sis/StudentInformationSystem'))
);
const TableOrderingSystem = Loadable(
  lazy(() => import('./pages/products/pages/tos/TableOrderingSystem'))
);
const WaterRefilling = Loadable(
  lazy(() => import('./pages/products/pages/water-refilling/WaterRefilling'))
);
const WebApp = Loadable(
  lazy(() => import('./pages/products/pages/webapp/WebApp'))
);

const CommunityProfiling = Loadable(
  lazy(
    () =>
      import('./pages/products/pages/community-profiling/CommunityProfiling')
  )
);

const IdIssuance = Loadable(
  lazy(() => import('./pages/products/pages/id-issuance/IdIssuance'))
);

const Lending = Loadable(
  lazy(() => import('./pages/products/pages/lending/Lending'))
);

const Biography = Loadable(
  lazy(() => import('./pages/about/pages/biography/Biography'))
);
const Legacy = Loadable(
  lazy(() => import('./pages/about/pages/legacy/Legacy'))
);

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<MainLayout />} errorElement={<ErrorPage />}>
        <Route index element={<Home />} />
        <Route
          path="about"
          element={<AboutLayout />}
          errorElement={<ErrorPage />}
        >
          <Route index element={<About />} />
          <Route path="biography" element={<Biography />} />
          <Route path="legacy" element={<Legacy />} />
        </Route>
        <Route
          path="products"
          element={<ProductsLayout />}
          errorElement={<ErrorPage />}
        >
          <Route index element={<Products />} />
          <Route
            path="attendance-and-payroll"
            element={<AttendanceAndPayroll />}
          />
          <Route
            path="hospital-management-system"
            element={<HospitalManagementSystem />}
          />
          <Route
            path="integrated-solid-waste-management"
            element={<IntegratedSolidWasteManagementSystem />}
          />
          <Route path="point-of-sale" element={<PointOfSale />} />
          <Route
            path="reservation-and-booking"
            element={<ReservationAndBooking />}
          />
          <Route
            path="student-information-system"
            element={<StudentInformationSystem />}
          />
          <Route
            path="table-ordering-system"
            element={<TableOrderingSystem />}
          />
          <Route path="water-refilling-system" element={<WaterRefilling />} />
          <Route path="webapp" element={<WebApp />} />
          <Route path="CommunityProfiling" element={<CommunityProfiling />} />
          <Route path="IdIssuance" element={<IdIssuance />} />
          <Route path="Lending" element={<Lending />} />
        </Route>
        <Route path="our-service" element={<OurService />} />
        <Route path="clients" element={<Clients />} />
        <Route path="associates" element={<Associates />} />
        <Route path="contact" element={<Contact />} action={contactAction} />

        <Route
          path="*"
          element={<div className="page-not-found">Page not found!</div>}
        />
      </Route>
    )
  );

  React.useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease',
    });
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
