import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

interface FooterCustomLinkProps {
  to: string;
  children: React.ReactNode;
}

function FooterCustomLink({ to, children }: FooterCustomLinkProps) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <Link to={to} className={`py-2 d-block ${isActive ? "text-white" : ""}`}>
      {children}
    </Link>
  );
}

export default FooterCustomLink;
