import type { ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
// import experimentalStyled from '@mui/styled-engine';

interface ProductsLayoutProps {
  children?: ReactNode;
}

// const MainLayoutRoot = experimentalStyled('div')(({ theme }: any) => ({
//   backgroundColor: theme.palette.background.default,
//   height: '100%',
//   paddingTop: 64,
// }));

function ProductsLayout({ children }: ProductsLayoutProps) {
  return (
    <>
      {children || (
        <>
          <Outlet />
        </>
      )}
    </>
  );
}

ProductsLayout.propTypes = {
  children: PropTypes.node,
};

export default ProductsLayout;
