import React from 'react';
import {
  ActionFunctionArgs,
  Form,
  Link,
  useActionData,
} from 'react-router-dom';

interface IUseActionData {
  isMailSent: boolean;
}

const Contact = () => {
  const data = useActionData() as IUseActionData;

  return (
    <>
      <div
        className="hero-wrap hero-wrap-2"
        style={{
          backgroundImage: 'url(images/hotel.jpg)',
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container-fluid">
          <div
            className="row no-gutters d-flex slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div
              className="col-md-6 text-center"
              data-aos="fade-up"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <p
                className="breadcrumbs"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                <span className="mr-2">
                  <Link to="/" reloadDocument>
                    Home
                  </Link>
                </span>
                <span>Contact Us</span>
              </p>
              <h1
                className="mb-3 bread"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                Contact
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section contact-section ftco-degree-bg">
        <div className="container">
          <div className="row d-flex mb-5 contact-info">
            <div className="col-md-12 mb-4">
              <h2 className="h4">Contact Information</h2>
            </div>
            <div className="w-100"></div>
            <div className="col-md-3">
              <p>
                <span>
                  <strong>Registered Address:</strong>
                </span>{' '}
                Purok 7, Nabo-alao, Basay, Negros Oriental 6222 PHILIPPINES
              </p>
            </div>
            <div className="col-md-3">
              <p>
                <span>
                  <strong>Email:</strong>
                </span>
                <a href="mailto:info@tardigradedigitals.com">
                  info@tardigradedigitals.com
                </a>
              </p>
              <p>
                <span>
                  <strong>Phone:</strong>
                </span>
                <a href="mailto:info@tardigradedigitals.com">
                  +63 963 6084 879
                </a>
              </p>
            </div>
          </div>
          <div className="row block-9">
            <div className="col-md-6 pr-md-5 align-self-center">
              <Form
                className="gform pure-form pure-form-stacked"
                method="post"
                data-email="example@email.net"
                action="/contact"
              >
                <div
                  className="form-elements"
                  style={{ display: data?.isMailSent ? 'none' : 'block' }}
                >
                  <fieldset className="form-group">
                    <input
                      id="name"
                      className="form-control"
                      name="name"
                      required
                      placeholder="Name"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <input
                      id="email"
                      className="form-control"
                      name="email"
                      type="email"
                      required
                      placeholder="your.name@email.com"
                    />
                    <span className="email-invalid" style={{ display: 'none' }}>
                      Must be a valid email address
                    </span>
                  </fieldset>
                  <fieldset className="form-group">
                    <input
                      type="number"
                      id="phone"
                      className="form-control"
                      name="phone"
                      required
                      placeholder="Phone Number"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <textarea
                      id="message"
                      className="form-control"
                      name="message"
                      rows={10}
                      required
                      placeholder="Tell us what's on your mind..."
                    ></textarea>
                  </fieldset>
                  <button className="btn btn-primary py-3 px-5">
                    <i className="fa fa-paper-plane"></i>&nbsp;Send
                  </button>
                </div>
                <div
                  className="thankyou_message"
                  style={{ display: data?.isMailSent ? 'block' : 'none' }}
                  data-aos="fade-up"
                >
                  <h2>Thank you for Contacting us!</h2>
                  <h5 style={{ color: '#2f89fc' }}>
                    We will get back to you soon!
                  </h5>
                </div>
              </Form>
            </div>
            <div className="col-md-6 pr-md-5">
              <div className="work-entry">
                <img alt="" src="images/about.jpg" className="img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

export const contactAction = async (action: ActionFunctionArgs) => {
  const { request } = action;
  const data = await request.formData();
  const submission = {
    name: data.get('name'),
    email: data.get('email'),
    phone: data.get('phone'),
    message: data.get('message'),
  };

  const errors = { isMailSent: false };

  try {
    const response = await fetch(
      'https://tardigradedigitals.com/api/send-inquiry',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(submission),
      }
    );

    if (response) {
      const textResponse = await response.text();

      if (textResponse === 'Mail Sent') {
        errors.isMailSent = true;
      } else {
        errors.isMailSent = false;
      }
    }
  } catch (_error) {
    errors.isMailSent = false;
  }

  return errors;
};
