import type { ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
// import experimentalStyled from '@mui/styled-engine';

interface MainLayoutProps {
  children?: ReactNode;
}

// const MainLayoutRoot = experimentalStyled('div')(({ theme }: any) => ({
//   backgroundColor: theme.palette.background.default,
//   height: '100%',
//   paddingTop: 64,
// }));

function MainLayout({ children }: MainLayoutProps) {
  return (
    <div>
      {children || (
        <>
          <NavBar />
          <Outlet />
          <Footer />
        </>
      )}
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
