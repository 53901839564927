import React from 'react';
import * as THREE from 'three';
import './home.css';
import { Link } from 'react-router-dom';
import { CgWebsite } from 'react-icons/cg';

function Home() {
  // const [visitorName, setVisitorName] = useState<React.ChangeEventHandler<HTMLInputElement> | undefined>();
  // const [visitorEmailAddress, setVisitorEmailAddress] = useState('');
  // const [visitorPhoneNumber, setVisitorPhoneNumber] = useState('');
  // const [visitorMessage, setVisitorMessage] = useState('');

  React.useEffect(() => {
    window.addEventListener('resize', onWindowResize, false);
    const container = document.getElementById('sphereContainer');
    const width = container!.clientWidth;
    const height = container!.clientHeight;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(40, width / height, 1, 10000);
    const renderer = new THREE.WebGLRenderer({
      canvas: document.getElementById('scene') ?? undefined,
      antialias: true,
      alpha: true,
    });
    const geometry = new THREE.SphereGeometry(1, 20, 15);
    const material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      wireframe: true,
    });
    const sphere = new THREE.Mesh(geometry, material);

    camera.position.set(-0.5, 0, 500);
    scene.add(sphere);
    scene.add(camera);
    renderer.setSize(width, height);
    container?.appendChild(renderer.domElement);
    renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );

    function update() {
      sphere.rotation.x += 0.002;
      sphere.rotation.y += 0.002;

      requestAnimationFrame(update);
      renderer.render(scene, camera);
    }

    function onWindowResize() {
      camera.aspect = container!.clientWidth / container!.clientHeight;

      camera.updateProjectionMatrix();

      renderer.setSize(container!.clientWidth, container!.clientHeight);
    }

    function start() {
      camera.position.z = 3;
      // Kick off update loop
      update();
    }

    start();
  }, []);
  return (
    <div>
      <div className="hero-wrap">
        <div className="overlay"></div>
        <div className="container-fluid">
          <div
            className="slider-text d-md-flex align-items-center"
            data-aos="fade-up"
            data-scrollax-parent="true"
          >
            <div
              className="one-forth align-self-md-center"
              data-scrollax=" properties: { translateY: '70%' }"
            >
              <h1 className="mb-4">
                {' '}
                Offering the best{' '}
                <strong
                  className="typewrite"
                  data-period="4000"
                  data-type='["Quality", "Price", "Value"]'
                >
                  <span className="wrap"></span>
                </strong>
              </h1>
              <p
                className="mb-md-5 mb-sm-3"
                data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
              >
                <strong>
                  Affordable products and services with great value.
                </strong>
                <br></br>
                <br></br> Tardigrade Digital Solutions, prioritizing user
                experience and product quality.
              </p>
              <p data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
                <a
                  href="#service"
                  className="btn btn-primary px-4 py-3 js-scroll-trigger"
                >
                  Services
                </a>{' '}
                <a
                  href="#product"
                  className="btn js-scroll-trigger btn-primary btn-outline-primary px-4 py-3 js-scroll-trigger"
                >
                  Products
                </a>
              </p>
            </div>
            <div
              className="one-half align-self-md align-self-sm-center"
              data-scrollax=" properties: { translateY: '10%' }"
            >
              <div
                className="sphere"
                id="sphereContainer"
                data-scrollax="properties: { translateY: '10%', opacity: 1.6 }"
              >
                <canvas id="scene"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="ftco-section ftco-section-2">
        <div className="overlay"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-md-6 text-center heading-section"
              data-aos="fade-up"
            >
              <div className="section-image-container">
                <div className="hmv-logo-yellow"></div>
              </div>
              <h2 className="heading-white mb-2">
                Tardigrade Digital Solutions
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section bg-light">
        <div className="row justify-content-center">
          <div
            className="col-md-8 text-center heading-section"
            data-aos="fade-up"
          >
            <div className="block-7 services p-5">
              <div className="media-body p-2 mt-3">
                <h3 className="heading">Why choose our services?</h3>
                <hr className="sep2" />
                <p className="mb-4">
                  Tardigrade Digital Solutions offers customized web-based
                  application for businesses. The company undertakes maintenance
                  and security of their products ensuring satisfaction to its
                  clients.
                </p>
                <p className=" mb-4">
                  As part of the turnover procedures for each project,
                  Tardigrade provides online trainings. Assisting the client's
                  team in understanding all of the new product's features and
                  procedures.
                </p>
              </div>
              <a
                href="about"
                className="btn btn-primary px-4 py-3"
                data-aos="fade-up"
              >
                More About Us
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ftco-section ftco-services line-gradient"
        id="service"
      >
        <div className="container">
          <div className="row justify-content-center mb-5 pb-5">
            <div
              className="col-md-7 text-center heading-section"
              data-aos="fade-up"
            >
              <h2 className="mb-2">Our Services</h2>
              <hr className="sep2" />
              <span className="subheading">Here's what we Offer</span>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-4 d-flex align-self-stretch"
              data-aos="fade-up"
            >
              <div className="media block-6 services d-block text-center">
                <div className="d-flex justify-content-center circle-bg circle2">
                  <div className="icon d-flex justify-content-center align-items-center">
                    <span className="flaticon-data"></span>
                  </div>
                </div>
                <div className="media-body p-2 mt-3">
                  <h3 className="heading">
                    Website and Application Development
                  </h3>
                  <p>
                    User-friendly interface with full feature functionality that
                    fits the trend
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 d-flex align-self-stretch"
              data-aos="fade-up"
            >
              <div className="media block-6 services d-block text-center">
                <div className="d-flex justify-content-center circle-bg">
                  <div className="icon d-flex justify-content-center align-items-center">
                    <span className="flaticon-gpu"></span>
                  </div>
                </div>
                <div className="media-body p-2 mt-3">
                  <h3 className="heading">
                    Computer Hardware and Software Services
                  </h3>
                  <p>
                    Efficiency on setting up computer hardware and software
                    appropriate for its use.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 d-flex align-self-stretch"
              data-aos="fade-up"
            >
              <div className="media block-6 services d-block text-center">
                <div className="d-flex justify-content-center circle-bg circle2">
                  <div className="icon d-flex justify-content-center align-items-center">
                    <span className="flaticon-cctv"></span>
                  </div>
                </div>
                <div className="media-body p-2 mt-3">
                  <h3 className="heading">Security Services</h3>
                  <p>
                    Securing Businesses and homes with security cameras
                    installation
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div
              className="col-md-4 d-flex align-self-stretch"
              data-aos="fade-up"
            >
              <div className="media block-6 services d-block text-center">
                <div className="d-flex justify-content-center circle-bg">
                  <div className="icon d-flex justify-content-center align-items-center">
                    <span className="flaticon-repair"></span>
                  </div>
                </div>
                <div className="media-body p-2 mt-3">
                  <h3 className="heading">Computer Repair and Maintenance</h3>
                  <p>
                    Solutions with Software and Hardware issues at minimum cost.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-md-4 d-flex align-self-stretch"
              data-aos="fade-up"
            >
              <div className="media block-6 services d-block text-center">
                <div className="d-flex justify-content-center circle-bg">
                  <div className="icon d-flex justify-content-center align-items-center">
                    <span className="flaticon-data"></span>
                  </div>
                </div>
                <div className="media-body p-2 mt-3">
                  <h3 className="heading">
                    Third Party Web Application Development
                  </h3>
                  <p>
                    Unlock the full potential of your business with our
                    Third-Party Web Application Development Service.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-12 d-flex align-self-stretch mb-5"
              data-aos="fade-up"
            >
              <div className="media block-6 services d-block text-center">
                <div className="media-body p-2 mt-3">
                  <p>Know more about what we offer</p>
                </div>
                <a
                  href="products"
                  className="btn btn-primary px-4 py-3 mb-5 px-5"
                  data-aos="fade-up"
                >
                  Products
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ftco-section img"
        id="section-counter"
        style={{ backgroundImage: 'url(images/bg_1.jpg)' }}
        data-stellar-background-ratio="0.5"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div
                  className="col-md-5 d-flex align-self-stretch"
                  data-aos="fade-up"
                >
                  <div className="media block-6 services d-block text-center">
                    <div className="d-flex justify-content-center ">
                      <div className="icon">
                        <span
                          className="icon-flag"
                          style={{ color: 'white' }}
                        ></span>
                      </div>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3
                        className="heading-white mb-2"
                        style={{ fontSize: 25 }}
                      >
                        Mission
                      </h3>
                      <p className="heading-white">
                        Empowering businesses through innovative and tailored
                        technology solutions for sustained growth and
                        efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-2 d-flex align-self-stretch"
                  data-aos="fade-up"
                ></div>
                <div
                  className="col-md-5 d-flex align-self-stretch"
                  data-aos="fade-up"
                >
                  <div className="media block-6 services d-block text-center">
                    <div className="d-flex justify-content-center">
                      <div className="icon">
                        <span
                          className="icon-eye"
                          style={{ color: 'white' }}
                        ></span>
                      </div>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3
                        className="heading-white mb-2"
                        style={{ fontSize: 25 }}
                      >
                        Vision
                      </h3>
                      <p className="heading-white">
                        Elevating business with cutting-edge technology
                        solutions for enhanced efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div
                  className="col-md-5 d-flex align-self-stretch"
                  data-aos="fade-up"
                >
                  <div className="media block-6 services d-block text-center">
                    <div className="d-flex justify-content-center ">
                      <div className="icon">
                        <span
                          className="icon-wb_sunny"
                          style={{ color: 'white' }}
                        ></span>
                      </div>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3
                        className="heading-white mb-2"
                        style={{ fontSize: 25 }}
                      >
                        Goals
                      </h3>
                      <p className="heading-white">
                        Accelerating digital experience by consistently
                        delivering high-performance and user-centric solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-5 d-flex align-self-stretch"
                  data-aos="fade-up"
                >
                  <div className="media block-6 services d-block text-center">
                    <div className="d-flex justify-content-center ">
                      <div className="icon">
                        <span
                          className="icon-asterisk"
                          style={{ color: 'white' }}
                        ></span>
                      </div>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3
                        className="heading-white mb-2"
                        style={{ fontSize: 25 }}
                      >
                        Purpose
                      </h3>
                      <p className="heading-white">
                        Enabling progress by providing essential and efficient
                        computer solutions that amplify productivity.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-2 d-flex align-self-stretch"
                  data-aos="fade-up"
                ></div>
                <div
                  className="col-md-5 d-flex align-self-stretch"
                  data-aos="fade-up"
                >
                  <div className="media block-6 services d-block text-center">
                    <div className="d-flex justify-content-center">
                      <div className="icon">
                        <span
                          className="icon-radio_button_checked"
                          style={{ color: 'white' }}
                        ></span>
                      </div>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3
                        className="heading-white mb-2"
                        style={{ fontSize: 25 }}
                      >
                        Core Values
                      </h3>
                      <p className="heading-white">
                        Innovation, Integrity, Collaboration: Upholding these
                        core values to deliver exceptional technological
                        solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section ftco-work" id="product">
        <div className="container-fluid">
          <div className="row justify-content-center mb-5 pb-5">
            <div
              className="col-md-7 text-center heading-section"
              data-aos="fade-up"
            >
              <h2 className="mb-2">Our Products</h2>
              <hr className="sep2" />
              <span className="subheading">
                Choose the product that fits your needs and connect with us
                today!
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4" data-aos="fade-up">
              <div className="work-entry">
                <Link
                  to="products/integrated-solid-waste-management"
                  className="img"
                  style={{ backgroundImage: 'url(images/iswm-system.jpg)' }}
                >
                  <div className="text d-flex justify-content-center align-items-center">
                    <div className="p-3">
                      <span>Management System</span>
                      <h3>Integrated Solid Waste Management System</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-4" data-aos="fade-up">
              <div className="work-entry">
                <Link
                  to="products/reservation-and-booking"
                  className="img"
                  style={{ backgroundImage: 'url(images/reservation.jpg)' }}
                >
                  <div className="text d-flex justify-content-center align-items-center">
                    <div className="p-3">
                      <span>Management System</span>
                      <h3>Online Reservation and Booking System</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-4" data-aos="fade-up">
              <div className="work-entry">
                <Link
                  to="products/point-of-sale"
                  className="img"
                  style={{
                    backgroundImage:
                      'url(images/products/preview/PointofSaleandInventorySystem.jpg)',
                  }}
                >
                  <div className="text d-flex justify-content-center align-items-center">
                    <div className="p-3">
                      <span>Management System</span>
                      <h3>Point of Sales and Inventory System</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-4" data-aos="fade-up">
              <div className="work-entry">
                <Link
                  to="products/webapp"
                  className="img"
                  style={{
                    backgroundImage:
                      'url(images/products/preview/WebApplicationDevelopment.jpg)',
                  }}
                >
                  <div className="text d-flex justify-content-center align-items-center">
                    <div className="p-3">
                      <span>Management System</span>
                      <h3>Web Application Development</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-4" data-aos="fade-up">
              <div className="work-entry">
                <Link
                  to="products/attendance-and-payroll"
                  className="img"
                  style={{
                    backgroundImage:
                      'url(images/products/preview/AttendaceAndPayrollSystem.jpg)',
                  }}
                >
                  <div className="text d-flex justify-content-center align-items-center">
                    <div className="p-3">
                      <span>Management System</span>
                      <h3>Attendance and Payroll System</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-4" data-aos="fade-up">
              <div className="work-entry">
                <Link
                  to="products/CommunityProfiling"
                  className="img"
                  style={{
                    backgroundImage:
                      'url(images/products/preview/CommunityProfilingSystem.jpg)',
                  }}
                >
                  <div className="text d-flex justify-content-center align-items-center">
                    <div className="p-3">
                      <span>Management System</span>
                      <h3>Community Profiling System</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12 d-flex align-self-stretch"
              data-aos="fade-up"
            >
              <div className="media block-6 services d-block text-center">
                <a
                  href="products"
                  className="btn btn-primary px-4 py-3"
                  data-aos="fade-up"
                >
                  Check for More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-5">
            <div
              className="col-md-7 text-center heading-section"
              data-aos="fade-up"
            >
              <h2 className="mb-3">Founders and Owners</h2>
              <hr className="sep2" />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/carlo.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Carlo Villalon</h3>
                  <hr className="sep" />
                  <p className="text-primary font-15">
                    <strong>Project Manager | IT Specialist</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/loreto.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Loreto Frallon</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>IT Engineer | System Admin</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/roger.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>

                  <h3 className="heading-2">Roger Tubog Jr.</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Lead Software Engineer</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mb-5 pb-5">
            <div
              className="col-md-7 text-center heading-section"
              data-aos="fade-up"
            >
              <h2 className="mb-3">Administrative Department</h2>
              <hr className="sep2" />
              {/* <span className="subheading">A solid Team thinking alike</span> */}
            </div>
          </div>

          {/* Administrative Department */}

          <div className="row justify-content-center">
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/rizzel.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>

                  <h3 className="heading-2">Rizzel Martizano</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Documentation Specialist</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/cate.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Cate Nicole Mongcopa</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Copy Editor</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{
                        backgroundImage:
                          'url(images/profile/LorurinMartizano.jpg)',
                      }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Lourin Martizano</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Administrative Assistant</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{
                        backgroundImage:
                          'url(images/profile/VinjoVillalon.jpg)',
                      }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>

                  <h3 className="heading-2">Vince Jonathan</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Administrative Assistant</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mb-5 pb-5 mt-5">
            <div
              className="col-md-7 text-center heading-section"
              data-aos="fade-up"
            >
              <h2 className="mb-3">Systems and Software Development Team</h2>
              <hr className="sep2" />
              <span className="subheading">A solid Team thinking alike</span>
            </div>
          </div>

          {/* Systems and Software Development Team */}

          <div className="row justify-content-center">
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/Team/Nichol.png)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Nichol Ray Tingson</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>UI/UX Designer | Software Engineer</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/Team/ramil.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Ramil Madeloso</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Software Engineer</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/nick.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Nick Sanchez</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>UI/UX Designer</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/neil_steven.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Neil Steven Villamil</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Software Engineer</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/rewachand.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Rewachand Lucero</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>IT Hardware Specialist</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{
                        backgroundImage: 'url(images/SarahJaneVillalon.jpg)',
                      }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Sarah Jane Villalon</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Data Analyst</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mb-5 pb-5 mt-5">
            <div
              className="col-md-7 text-center heading-section"
              data-aos="fade-up"
            >
              <h2 className="mb-3">Marketing Representative</h2>
              <hr className="sep2" />
            </div>
          </div>

          {/* MARKETING REPRESENTATIVES TEAM */}

          <div className="row justify-content-center">
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{ backgroundImage: 'url(images/cherryl.jpg)' }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>
                  <h3 className="heading-2">Cherryl Carballo</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Marketing Representative</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{
                        backgroundImage: 'url(images/profile/cherry-bless.jpg)',
                      }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>

                  <h3 className="heading-2">Cherry Bless Belotindos</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Marketing Representative</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{
                        backgroundImage: 'url(images/profile/aurea.jpg)',
                      }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>

                  <h3 className="heading-2">Aurea Mae Masayon</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Marketing Representative</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{
                        backgroundImage: 'url(images/profile/reah.jpg)',
                      }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>

                  <h3 className="heading-2">Reah Jane Culagbang</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Marketing Representative</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="block-7">
                <div className="text-center">
                  <div className="avatar">
                    <div
                      className="user-img mb-5"
                      style={{
                        backgroundImage: 'url(images/profile/cherene.jpg)',
                      }}
                    >
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-star"></i>
                      </span>
                    </div>
                  </div>

                  <h3 className="heading-2">Cherene Bongcawel</h3>
                  <hr className="sep" />
                  <p className="text-primary">
                    <strong>Marketing Representative</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-md-6 pr-md-5 py-5 choose block-7 blueblock">
              <div className="heading-section heading-section-white mb-5">
                <h2 className="mb-2">Get in Touch with Us</h2>
              </div>
              <div className="text-white" data-aos="fade-up">
                <p>
                  For inquiries or collaboration opportunities, feel free to get
                  in touch with us. We look forward to hearing from you!
                </p>
                <ul className="un-styled my-5 pl-1">
                  <li style={{ listStyleType: 'none' }}>
                    <span className="icon-check"></span>&nbsp;&nbsp;Prioritizing
                    Quality
                  </li>
                  <li style={{ listStyleType: 'none' }}>
                    <span className="icon-check"></span>&nbsp;&nbsp;Best Pricing
                  </li>
                  <li style={{ listStyleType: 'none' }}>
                    <span className="icon-check"></span>&nbsp;&nbsp;Reliable
                    Services
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 py-5 pl-md-5 block-7">
              <div className="heading-section mb-5"></div>
              <form
                className="gform pure-form pure-form-stacked"
                method="POST"
                data-email="example@email.net"
                action="https://script.google.com/macros/s/AKfycbw-lBzuR89iCvMZL6mtWOg_TVlZ9XEW21hVY0MejOwJOL9xYOYs/exec"
              >
                <div className="row">
                  <div className="form-elements" style={{ width: '100%' }}>
                    <div className="col col-md-8">
                      <fieldset className="form-group">
                        <input
                          id="name"
                          className="form-control"
                          name="name"
                          placeholder="Name"
                        />
                      </fieldset>
                    </div>
                    <div className="col col-md-8">
                      <fieldset className="form-group">
                        <input
                          id="email"
                          className="form-control"
                          name="email"
                          type="email"
                          required
                          placeholder="your.name@email.com"
                        />
                        <span
                          className="email-invalid"
                          style={{ display: 'none' }}
                        >
                          Must be a valid email address
                        </span>
                      </fieldset>
                    </div>
                    <div className="col col-md-6">
                      <fieldset className="form-group">
                        <input
                          type="number"
                          id="phone"
                          className="form-control"
                          name="phone"
                          placeholder="Phone Number"
                        />
                      </fieldset>
                    </div>
                    <div className="col col-md-12">
                      <fieldset className="form-group">
                        <textarea
                          id="message"
                          className="form-control"
                          name="message"
                          rows={10}
                          placeholder="Tell us what's on your mind..."
                        ></textarea>
                      </fieldset>
                    </div>
                    <div className="col col-md-12">
                      <button className="btn btn-primary py-3 px-5">
                        <i className="fa fa-paper-plane"></i>&nbsp;Send Message
                      </button>
                    </div>
                  </div>
                  <div
                    className="thankyou_message"
                    style={{ display: 'none' }}
                    data-aos="fade-up"
                  >
                    <h2>Thank you for Contacting us!</h2>
                    <h5 style={{ color: '#2f89fc' }}>
                      We will get back to you soon!
                    </h5>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
