import React from 'react';
import NavCustomLink from './NavCustomLink';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
      id="ftco-navbar"
    >
      <div className="container">
        <Link className="navbar-brand" to="/">
          <div className="hmv-logo"></div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="oi oi-menu"></span> Menu
        </button>

        <div className="collapse navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            <NavCustomLink to="/">Home</NavCustomLink>
            <NavCustomLink to="about">About</NavCustomLink>
            <NavCustomLink to="products">Products</NavCustomLink>
            <NavCustomLink to="our-service">Services</NavCustomLink>
            <NavCustomLink to="clients">Clients</NavCustomLink>
            <NavCustomLink to="associates">Associates</NavCustomLink>
            <NavCustomLink to="contact">Contact Us</NavCustomLink>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
