import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

interface NavCustomLinkProps {
  to: string;
  children: React.ReactNode;
}

function NavCustomLink({ to, children }: NavCustomLinkProps) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  console.log('route: ', to);
  return (
    <li className={`nav-item ${isActive ? 'active' : ''}`}>
      <Link
        to={to}
        reloadDocument={to === '/' ? true : false}
        className="nav-link"
      >
        {children}
      </Link>
    </li>
  );
}

export default NavCustomLink;
