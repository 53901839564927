import React from 'react';
import FooterCustomLink from './FooterCustomLink';

function Footer() {
  return (
    <footer className="ftco-footer ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="ftco-footer-widget mb-4 ml-md-5">
              <div className="row text-center justify-content-center">
                <div className="">
                  <FooterCustomLink to="/">
                    <strong>Home</strong>
                  </FooterCustomLink>
                </div>
                <div className="mx-5">
                  <FooterCustomLink to="/about">
                    <strong>About</strong>
                  </FooterCustomLink>
                </div>
                <div className="mx-5">
                  <FooterCustomLink to="/products">
                    <strong>Products</strong>
                  </FooterCustomLink>
                </div>
                <div className="mx-5">
                  <FooterCustomLink to="/our-service">
                    <strong>Services</strong>
                  </FooterCustomLink>
                </div>
                <div className="mx-5">
                  <FooterCustomLink to="/clients">
                    <strong>Clients</strong>
                  </FooterCustomLink>
                </div>
                <div className="mx-5">
                  <FooterCustomLink to="/associates">
                    <strong>Associates</strong>
                  </FooterCustomLink>
                </div>
                <div className="mx-5">
                  <FooterCustomLink to="/contact">
                    <strong>Contact Us</strong>
                  </FooterCustomLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-1 justify-content-center">
          <div className="col-md-2">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Tardigrade Digital Solutions</h2>
              <br></br>
              <p>Prioritizing best value products and services.</p>
            </div>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3">
            <div className="ftco-footer-widget mb-4">
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <a href="/#">
                      <span className="icon icon-map-marker"></span>
                      <span className="text">
                        Purok 7, Nabo-alao, Basay, Negros Oriental 6222
                        PHILIPPINES
                      </span>
                    </a>
                    <span className="mg-lc">Registered Address</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="ftco-footer-widget mb-4">
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <a href="/#">
                      <span className="icon icon-envelope"></span>
                      <span className="text">info@tardigradedigitals.com</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="ftco-footer-widget mb-4">
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <a href="/#">
                      <span className="icon icon-phone"></span>
                      <span className="text">+63 963 6084 879</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
