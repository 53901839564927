import type { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

interface AboutLayoutProps {
  children?: ReactNode;
}

function AboutLayout({ children }: AboutLayoutProps) {
  return (
    <>
      {children || (
        <>
          <Outlet />
        </>
      )}
    </>
  );
}

AboutLayout.propTypes = {
  children: PropTypes.node,
};

export default AboutLayout;
